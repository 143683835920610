<template>

  <div id="form-input">

  <!-- <div v-if="value"> -->

    <div class="form-header">

      <div class="description">
        {{ formDetail.description }}
      </div>
      <div
        class="help-info"
        v-if="formDetail.help_info"
      >
        {{ formDetail.help_info }}
      </div>

    </div>

    <div class="form-body">

      <input
        ref="focusForm"
        v-model.trim="value.value"
        @input="
          $emit('input', {
            id: formDetail.id,
            value: getEmitedValue($event.target.value),
          });
          setWritingTime(500);
        "
        :type="
          validInputTypes.includes(formDetail.form_subtype)
            ? formDetail.form_subtype
            : 'text'
        "
        class="w-full text-secondary px-5 text-2xl md:text-3xl border border-primary border-r-0 border-l-0 border-t-0"
        :class="[errors.length && showErrors ? 'invalid' : '']"
        :placeholder="formDetail.placeholder"
        v-on:keyup.enter="gotoNext()"
      />
      
      <transition name="fade-pop">
        <div
          v-if="showErrors == true"
          class="w-full flex flex-wrap justify-end items-center"
        >
          <div
            class="
              w-full
              bg-red-100
              text-red-500
              border-2
              rounded-md
              border-red-400
              px-10
              py-1
              mb-5
              mt-2
            "
            v-for="(error, index) in errors"
            :key="'err-' + index"
          >
            {{ error }}
          </div>
        </div>
      </transition>
      
    </div>
    
    <div class="form-footer">
      <button
        class="px-5 py-2 border-2 border-primary rounded-md text-primary hover:bg-primary hover:text-white transition duration-600 pop"
        @click="gotoPrevious()"
      >
        Anterior
      </button>
      <button
        class="px-5 py-2 border-2 border-primary rounded-md text-primary hover:bg-primary hover:text-white transition duration-600 pop"
        @click="gotoNext()"
      >
        Siguiente
      </button>
    </div>

  </div>

</template>

<script>
import { mapGetters } from "vuex";

import {
  required,
  email,
  minLength,
  maxLength,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";

export default {
  name: "QuestionInput",
  data: function () {
    return {
      internValue: null,
      showErrors: false,
      canContinue: true,
      validInputTypes: [
        "email",
        "password",
        "date",
        "text",
        "number",
        "tel",
        "",
      ],
      timeout: null,
    };
  },
  validations() {
    let validations = {
      value: {
        value: {
          required,
        },
      },
    };

    if (!this.formDetail.is_mandatory) {
      validations.value.value["required"] = required;
    }

    if (this.formDetail.form_subtype == "email") {
      validations.value.value["email"] = email;
    }

    if (this.formDetail.form_subtype == "email") {
      validations.value.value["email"] = email;
    }

    if (this.formDetail.in_range.length > 0) {
      // If has range values
      // The validations depends of form type and its required values
      if (this.formDetail.form_type == "input") {
        if (this.formDetail.value_type == "str") {
          if (["text", "N/A", "email"].includes(this.formDetail.form_subtype)) {
            // Validations if min and max characters
            if (this.formDetail.in_range.length > 0)
              validations.value.value["minLength"] = minLength(
                this.formDetail.in_range[0]
              );
            if (this.formDetail.in_range.length > 1)
              validations.value.value["maxLength"] = maxLength(
                this.formDetail.in_range[1]
              );
          }
        }
        if (this.formDetail.value_type == "int") {
          if (["number"].includes(this.formDetail.form_subtype)) {
            // Validations if min and max characters
            if (this.formDetail.in_range.length > 0)
              validations.value.value["minValue"] = minValue(
                this.formDetail.in_range[0]
              );
            if (this.formDetail.in_range.length > 1)
              validations.value.value["maxValue"] = maxValue(
                this.formDetail.in_range[1]
              );
          }
        }
      }
    }
    return validations;
  },
  components: {

  },
  props: {
    formDetail: {
      type: Object,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {},
  },
  computed: {
    ...mapGetters(["loggedIn" , "userData"]),
    errors() {
      
      let errors = [];
      if (this.showErrors) {
        if (
          "required" in this.$v.value.value &&  !this.$v.value.value.required
        ) {
          errors.push("Este valor es necesario, por favor ingresa uno valido.");
          // print(this.$v.value.value)
        }
        if (
          "minLength" in this.$v.value.value && !this.$v.value.value.minLength
        ) {
          errors.push(`Debe tener al menos ${this.$v.value.value.$params.minLength.min} carácteres.`);
        }
        if (
          "maxLength" in this.$v.value.value && !this.$v.value.value.maxLength
        ) {
          errors.push(
            `Debe tener máximo de ${this.$v.value.value.$params.maxLength.max} carácteres.`
          );
        }
        if (
          "minValue" in this.$v.value.value && !this.$v.value.value.minValue
        ) {
          errors.push(
            `Debe tener mínimo ${this.$v.value.value.$params.minValue.min}.`
          );
        }
        if (
          "maxValue" in this.$v.value.value &&
          !this.$v.value.value.maxValue
        ) {
          errors.push(
            `Debe tener máximo ${this.$v.value.value.$params.maxValue.max}.`
          );
        }
        if ("email" in this.$v.value.value && !this.$v.value.value.email) {
          errors.push(
            "Parece que el email no tiene un formato correcto, usa el siguiente formato miCorreo@email.algo"
          );
        }
      }
      return errors;
    },
  },
  mounted() {
    let self = this;
    if (!self.value) {
      self.$emit("input", { id: self.formDetail.id, value: "" });
    }
    self.$nextTick(function () {
      self.$refs["focusForm"].focus();
    });
  

    try {
    //! Importante Revisar llenado de correo.
    if(this.formDetail.form_subtype == "email") {
      self.$emit("input", { id:self.formDetail.id , value: self.userData.email });
    }
    } catch (error) {
      self.$emit("input", {
        id: self.formDetail.id , value: [""]});
    }

  },
  methods: {
    getEmitedValue(value) {
      return value;
    },
    setWritingTime(time) {
      let self = this;
      self.isWriting = true;
      clearTimeout(self.timeout);
      self.timeout = setTimeout(() => {
        self.isWriting = false;
      }, time);
    },
    data_ready_w() {
      this.writingAnimation.totalFrames = this.animWriting.totalFrames;
    },
    handleAnimationW: function (anim) {
      let self = this;
      self.animWriting = anim;
      self.animWriting.addEventListener("data_ready", function () {
        self.writingAnimation.totalFrames = self.animWriting.totalFrames;
      });
    },
    data_ready_l() {
      this.listeningAnimation.totalFrames = this.animListening.totalFrames;
    },
    handleAnimationL: function (anim) {
      let self = this;
      self.animListening = anim;
      self.animListening.addEventListener("data_ready", function () {
        self.listeningAnimation.totalFrames = self.animListening.totalFrames;
      });
    },
    gotoPrevious() {
      this.$emit("previous");
    },
    gotoNext() {
      let self = this;
      // console.log('There')
      // console.log(self.value)
      // console.log(self.value.value)
      if (self.required) {
        if (self.isValid()) {
          if (self.value) {
            self.$emit("next");
          } else {
            self.canContinue = false;
          }
        }
      } else {
        self.$emit("next");
      }
    },
    isValid() {
      let is_valid = false;
      if (this.$v.value.value.$invalid) {
        this.showErrors = true;
      } else {
        is_valid = true;
        this.showErrors = false;
      }
      return is_valid;
    },
  },
};
</script>

<style>
input {
  outline: none;
}
</style>
